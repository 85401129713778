<template>
  <div class="m-payment-card">
    <div class="row-main">
      <div class="card-number">
        <span>{{ card.card_mask }}</span>
      </div>
      <div class="row-actions__delete" @click="handlerDeleteCard">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6667 4V13.3737C12.6667 13.7166 12.5262 14.0455 12.2761 14.288C12.0261 14.5304 11.687 14.6667 11.3333 14.6667H4.66667C4.31305 14.6667 3.97391 14.5304 3.72386 14.288C3.47381 14.0455 3.33334 13.7166 3.33334 13.3737V4H12.6667ZM6 7.33333H6.66667V10.6667H6V7.33333ZM10 7.33333H9.33334V10.6667H10V7.33333Z" fill="#333333" />
          <path d="M2 4.33301H14" stroke="#333333" />
          <mask id="path-3-inside-1_21364_2065" fill="white">
            <path d="M5.33334 3.99967V2.66634C5.33334 2.31272 5.47381 1.97358 5.72386 1.72353C5.97391 1.47348 6.31305 1.33301 6.66667 1.33301H9.33334C9.68696 1.33301 10.0261 1.47348 10.2761 1.72353C10.5262 1.97358 10.6667 2.31272 10.6667 2.66634V3.99967" />
          </mask>
          <path d="M4.33334 3.99967C4.33334 4.55196 4.78105 4.99967 5.33334 4.99967C5.88562 4.99967 6.33334 4.55196 6.33334 3.99967H4.33334ZM6.66667 1.33301V0.333008V1.33301ZM9.33334 1.33301V0.333008V1.33301ZM9.66667 3.99967C9.66667 4.55196 10.1144 4.99967 10.6667 4.99967C11.219 4.99967 11.6667 4.55196 11.6667 3.99967H9.66667ZM6.33334 3.99967V2.66634H4.33334V3.99967H6.33334ZM6.33334 2.66634C6.33334 2.57794 6.36845 2.49315 6.43097 2.43064L5.01675 1.01643C4.57917 1.45401 4.33334 2.0475 4.33334 2.66634H6.33334ZM6.43097 2.43064C6.49348 2.36813 6.57826 2.33301 6.66667 2.33301V0.333008C6.04783 0.333008 5.45434 0.57884 5.01675 1.01643L6.43097 2.43064ZM6.66667 2.33301H9.33334V0.333008H6.66667V2.33301ZM9.33334 2.33301C9.42174 2.33301 9.50653 2.36813 9.56904 2.43064L10.9833 1.01643C10.5457 0.57884 9.95217 0.333008 9.33334 0.333008V2.33301ZM9.56904 2.43064C9.63155 2.49315 9.66667 2.57794 9.66667 2.66634H11.6667C11.6667 2.0475 11.4208 1.45401 10.9833 1.01643L9.56904 2.43064ZM9.66667 2.66634V3.99967H11.6667V2.66634H9.66667Z" fill="#333333" mask="url(#path-3-inside-1_21364_2065)" />
        </svg>
      </div>

      <img
        v-if="image"
        :src="image"
        class="m-payment-card__background"
        alt="card"
      />
    </div>
    <div class="row-actions">
      <div
        class="sf-radio"
        :class="{
          'sf-radio--is-active': card.is_default
        }"
        @click="changeDefault"
      >
        <label class="sf-radio__container">
          <div class="sf-radio__checkmark" />
          <div class="sf-radio__content">
            <div class="sf-radio__label">
              {{ $t('default') }}
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MPaymentCard',
  props: {
    card: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data () {
    return {
      isChangeDefaultCard: false
    }
  },
  computed: {
    setChangeDefaultCard: {
      get () {
        return this.isChangeDefaultCard
      },
      set (status) {
        this.isChangeDefaultCard = status
      }
    },
    image () {
      return this.card?.skin_data?.data
        ? `data:image/png;base64,${this.card.skin_data.data}`
        : null
    }
  },
  methods: {
    ...mapActions({
      forceSetDefaultCard: 'user-payment-cards/forceSetDefaultCard',
      changeDefaultCard: 'user-payment-cards/changeDefaultCard',
      deleteCard: 'user-payment-cards/deleteCard',
      loadPaymentCardsList: 'user-payment-cards/loadPaymentCardsList',
      spawnNotification: 'notification/spawnNotification'
    }),
    async changeDefault () {
      this.$emit('handler-toggle-cards', true)

      const card = Object.assign({}, this.card)
      delete card.data_array
      card.is_default = !card.is_default

      this.forceSetDefaultCard({ entryCard: card })

      await this.updateCardData(this.changeDefaultCard, {
        phone: card.phone,
        card_mask: card.card_mask,
        card_type: card.card_type,
        is_default: card.is_default
      }, this.$t('Card was successfully changed'))

      this.$emit('handler-toggle-cards', false)
    },
    async updateCardData (action, data, successMessage) {
      const response = await action(data);

      if (response?.code === 200) {
        this.onSuccess(successMessage);
        this.loadPaymentCardsList();
      } else {
        // todo:: check error message text
        this.onFailure({ result: 'Error updating card data' });
      }
    },
    handlerDeleteCard () {
      this.updateCardData(this.deleteCard, this.card, this.$t('Card was successfully deleted'))
    },

    onSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: message,
        action1: { label: this.$t('OK') }
      });
    },
    onFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@storefront-ui/shared/styles/components/molecules/SfRadio.scss";
.m-payment-card {
  box-sizing: border-box;
  width: 271px;
  height: 215px;
  background-color: var(--light-gray);
  border-radius: var(--spacer-5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--font-family-secondary);
  color: var(--black);
  position: relative;
  overflow: hidden;
  z-index: 0;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
  }

  .row-main {
    padding: var(--spacer-25);
    position: relative;
    z-index: 1;
  }

  .row-actions {
    .sf-radio {
      width: 100%;
    }

    .sf-radio__container {
      padding: var(--spacer-10) var(--spacer-25);
    }
  }

  .row-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;

    .card-number {
      z-index: 1;
      font-size: var(--font-size-18);
      font-weight: var(--font-medium);

      span {
        display: inline-flex;
        align-items: center;
        height: 32px;
        background: var(--white);
        padding: 0 var(--spacer-10);
        border-radius: var(--spacer-15);
      }
    }
  }

  .row-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    ::v-deep {
      .sf-radio__container {
        align-items: center;

        .sf-radio__content {
          margin: 0 0 0 var(--spacer-10);

          .sf-radio__label {
            font-size: var(--font-size-14);
            font-weight: var(--font-normal);
            line-height: var(--font-size-17);
            color: var(--black);
          }
        }
      }

      .sf-radio:not(.sf-radio--is-active) {
        .sf-radio__checkmark {
          border: 1px solid var(--gray3);
          background-color: var(--white);
        }
      }
    }

    &__delete {
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: var(--white);
      cursor: pointer;
    }
  }
}
</style>
