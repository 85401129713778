<template>
  <div v-if="isBonusCardExist">
    <div
      v-if="isMobile || isBigSize"
      class="bonus-card_big"
      :class="{ 'cursor-default': isBigCardWithDiscount }"
    >
      <div class="bonus-card-row1">
        <div class="bonus-card-row1__text">
          <div v-if="isBigCardWithDiscount" class="bonus-card-row1__with-discount">
            <div class="bonus-card-row1__title">
              {{ $t('Varus card') }}
            </div>
            <div class="bonus-card-row1__bonus">
              <div class="bonus-card-row1__bonus-image-wrap">
                <img
                  src="/assets/account/card_bonus.svg"
                  alt="bonus_card"
                  width="14"
                >
              </div>
              <span class="bonus-card-row1__bonus-value">
              {{ card.percentage_discount }}
            </span>
              <span class="bonus-card-row1__bonus-title">
              {{ $t('bonus') }}
            </span>
            </div>
          </div>
          <div v-else class="bonus-card-row1__text1">
            {{ $t('Virtual card') }}
          </div>
          <div class="bonus-card-row1__text2">
            {{ card.number }}
          </div>
        </div>
        <div class="bonus-card-row1__image-wrap">
          <img src="/assets/account/bonus_card_qr.svg" alt="QR" width="36">
        </div>
      </div>
      <div class="bonus-card-row2">
        <div>
          <div><span class="bonus_value">{{ card.active_bonuses }}</span>грн</div>
          <div>{{ $t('Bonuses') }}</div>
        </div>
        <div>
          <div><span class="bonus_value">{{ card.cash_bonuses }}</span>грн</div>
          <div>{{ $t('Money') }}</div>
        </div>
      </div>
    </div>
    <div v-else class="bonus-card_small">
      <div class="bonus-card-row1">
        <div class="bonus-card-row1__title">
          {{ $t('Varus card') }}
        </div>
        <div class="bonus-card-row1__bonus">
          <div class="bonus-card-row1__bonus-image-wrap">
            <img
              src="/assets/account/card_bonus.svg"
              alt="bonus_card"
              width="8"
            >
          </div>
          <span class="bonus-card-row1__bonus-value">
            {{ card.percentage_discount }}
          </span>
          <span class="bonus-card-row1__bonus-title">
            {{ $t('bonus') }}
          </span>
        </div>
      </div>
      <div class="bonus-card-row2">
        {{ card.number }}
      </div>
    </div>
  </div>
</template>
<script>
import DeviceType from 'theme/mixins/DeviceType';
import { mapGetters } from 'vuex';

export default {
  name: 'MAccountBonusCard',
  mixins: [DeviceType],
  props: {
    isBigSize: {
      type: Boolean,
      default: false
    },
    isBigCardWithDiscount: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      isBonusCardExist: 'bonusCard/isBonusCardExist',
      card: 'bonusCard/getBonusCard'
    })
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.bonus-card_big {
  cursor: pointer;
  border-radius: var(--spacer-14);
  height: 225px;
  width: 355px;
  color: white;
  background-image: url('/assets/account/bonus-card_big.png');
  font-family: var(--font-family-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;

  .bonus-card-row1 {
    margin: var(--spacer-15) var(--spacer-15) 0 var(--spacer-30);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text1 {
      margin-top: var(--spacer-15);
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-18);
      font-weight: var(--font-medium);
      opacity: 0.7;
    }

    &__title {
      color: white;
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-18);
      font-weight: var(--font-medium);
      opacity: 0.7;
    }

    &__text2 {
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-24);
      font-weight: var(--font-bold);
    }

    &__with-discount {
      display: flex;
      margin-top: var(--spacer-15);
    }

    &__bonus {
      display: flex;
      align-items: center;
      height: var(--spacer-28);
      background-color: var(--green-light4);
      color: var(--black);
      border-radius: var(--spacer-15);
      margin: 0 0 0 var(--spacer-10);

      &-image-wrap {
        box-sizing: border-box;
        width: var(--spacer-28);
        height: var(--spacer-28);
        border: 1px solid var(--white);
        border-radius: 100%;
        background-color: var(--orange);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-value {
        font-size: var(--font-size-14);
        font-weight: var(--font-medium);
        padding: 0 3px;
      }

      &-title {
        font-size: var(--font-size-12);
        font-weight: var(--font-normal);
        padding-right: var(--spacer-9);
      }
    }

    &__image-wrap {
      box-sizing: border-box;
      width: 70px;
      height: 70px;
      border-radius: 7px;
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .bonus-card-row2 {
    box-sizing: border-box;
    height: 72px;
    margin: var(--spacer-15);
    font-size: var(--font-base);
    font-weight: var(--font-normal);
    color: var(--white);
    background-color: var(--green-light3);
    border-radius: 9px;
    padding-left: var(--spacer-20);
    padding-right: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bonus_value {
      font-size: var(--font-size-25);
      font-weight: var(--font-medium);
      margin-right: var(--spacer-xs);
    }
  }
}

.cursor-default {
  cursor: default;
}

.bonus-card_small {
  cursor: pointer;
  border-radius: var(--spacer-xs);
  height: 45px;
  @media (min-width: $desktop-min) {
    width: 355px
  }
  @media (min-width: $tablet-min) and (max-width: $tablet-max) {
    width: 230px;
  }
  max-width: 100%;
  background-image: url('/assets/account/bonus-card_small.png');
  font-family: var(--font-family-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .bonus-card-row1 {
    margin-left: var(--spacer-18);
    display: flex;
    align-items: center;

    &__title {
      color: white;
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-9);
      font-weight: var(--font-medium);
      opacity: 0.7;
    }

    &__bonus {
      display: flex;
      align-items: center;
      height: var(--spacer-16);
      background-color: var(--green-light4);
      color: var(--black);
      border-radius: var(--spacer-15);
      margin: 0 var(--spacer-5);

      &-image-wrap {
        box-sizing: border-box;
        width: var(--spacer-16);
        height: var(--spacer-16);
        border: 1px solid var(--white);
        border-radius: 100%;
        background-color: var(--orange);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-value {
        font-size: var(--font-size-8);
        font-weight: var(--font-medium);
        padding: 0 3px;
      }

      &-title {
        font-size: var(--font-size-7);
        font-weight: var(--font-normal);
        padding-right: var(--spacer-5);
      }
    }
  }

  .bonus-card-row2 {
    margin-left: var(--spacer-18);
    font-size: var(--font-size-12);
    font-weight: var(--font-bold);
    color: var(--white);
  }
}
</style>
