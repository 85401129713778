<template>
  <div class="o-my-account-viewed-products">
    <div
      v-if="isDesktop"
      class="viewed-products-head"
    >
      <div class="viewed-products-head__title">
        {{ $t('Viewed') }}
      </div>
    </div>
    <div class="viewed-products-wrap">
      <div
        v-if="isEmptyView"
        class="viewed-products-empty"
      >
        <div class="viewed-products-empty__placeholder">
          <svg width="164" height="164" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#E5E5E5" d="M0 0h164v164H0z" />
            <path fill="#F9F9FA" d="M-722-372H644V977H-722z" />
            <path fill="#fff" d="M-394 378h950v-505h-950z" />
            <circle cx="82" cy="82" r="82" fill="#F3F4F6" />
            <circle cx="82" cy="82" r="48" fill="#fff" />
            <g clip-path="url(#a)">
              <path d="M63.968 82c1.566-8.533 9.045-15 18.031-15 8.987 0 16.464 6.467 18.032 15-1.567 8.533-9.045 15-18.032 15-8.986 0-16.463-6.467-18.031-15Zm18.031 8.333a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666ZM82 87a5 5 0 1 1 0-10 5 5 0 0 1 0 10Z" fill="#333" />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" transform="translate(62 62)" d="M0 0h40v40H0z" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="viewed-products-empty__title">
          {{ $t('You have not looked at our products yet') }}
        </div>
        <div class="viewed-products-empty__message">
          {{ $t('Check out special offers and we will save the items you have viewed on this page') }}
        </div>
        <div class="viewed-products-empty__action">
          <SfButton
            data-transaction-name="Go To Special Offers"
            @click="goToSpecialOffers"
          >
            {{ $t("VARUS special promotions") }}
          </SfButton>
        </div>
      </div>
      <div
        v-else
        class="viewed-list-wrap"
      >
        <MCategoryList
          :is-loading="loading"
          :products="preparedProducts"
          class-list="products__grid top-grid"
          :wishlist-icon="true"
        />
      </div>
    </div>
    <SfButton
      v-show="totalPages > 1"
      class="products__load-more sf-button--primary"
      :disabled="loading"
      data-transaction-name="Account - Viewed Load More"
      @click="loadMoreFromPag"
    >
      <span>
        {{ $t('Show more {amount} products', { amount: showMoreCount }) }}
      </span>
    </SfButton>
  </div>
</template>

<script>
import DeviceType from 'theme/mixins/DeviceType';
import MCategoryList from 'theme/components/molecules/m-category-list';
import config from 'config'
import { SfButton } from '@storefront-ui/vue';
import { prepareCategoryProduct } from 'theme/helpers';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OMyAccountViewed',
  components: { SfButton, MCategoryList },
  mixins: [DeviceType],
  data () {
    return {
      currentPage: 1,
      perPage: config.recentlyViewed.pageSize || 8,
      productItems: [],
      totalCount: 0,
      counts: 0
    }
  },
  computed: {
    ...mapGetters({
      viewedProducts: 'recently-viewed/viewed',
      viewedTotal: 'recently-viewed/viewedTotal',
      loading: 'recently-viewed/loading'
    }),
    isEmptyView () {
      return !this.loading && !this.viewedProducts.length
    },
    preparedProducts: {
      get () {
        return this.productItems.map(prepareCategoryProduct) || []
      },
      set (items) {
        this.productItems.push(...items)
      }
    },
    totalPages () {
      const t = Math.ceil(this.viewedTotal / this.perPage)
      if (this.currentPage === t) return 0
      return t
    },
    showMoreCount: {
      get () {
        return this.totalCount >= this.perPage ? this.perPage : this.counts
      },
      set (n) {
        this.counts = n
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler () {
        this.productItems = []
        await this.resetFetchViewedProducts()

        const serverData = await this.getItems({ pageSize: this.perPage, currentPage: 1 })
        await this.updateItemsData(serverData)
        await this.updateRecentlyViewedProducts()

        this.preparedProducts = this.viewedProducts
      }
    }
  },
  created () {
    this.totalCount = this.viewedTotal - this.perPage
    this.showMoreCount = this.totalCount
  },
  methods: {
    ...mapActions({
      resetFetchViewedProducts: 'recently-viewed/resetFetchViewedProducts',
      getItems: 'recently-viewed/getItemsDataFromServer',
      updateItemsData: 'recently-viewed/updateItemsData',
      updateRecentlyViewedProducts: 'recently-viewed/updateRecentlyViewedProducts'
    }),
    goToSpecialOffers () {
      this.$router.push(this.localizedRoute('/rasprodazha'));
    },
    async loadMoreFromPag () {
      this.currentPage += 1
      const serverData = await this.getItems({ pageSize: this.perPage, currentPage: this.currentPage })

      this.totalCount = this.totalCount - this.perPage
      this.showMoreCount = this.totalCount

      await this.updateItemsData(serverData)
      await this.updateRecentlyViewedProducts()

      this.preparedProducts = this.viewedProducts
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';
@import "~theme/css/px2rem";

.o-my-account-viewed-products {
  .header-add-action {
    position: fixed;
    top: var(--spacer-9);
    right: var(--spacer-20);
    justify-content: flex-end;
    display: flex;
    z-index: var(--content-pages-mobile-sticky-index, 1);

    .header-add-action__button {
      @include font-icon(var(--icon-plus));

      &:before {
        color: var(--black);
        font-size: var(--font-size-16);
      }
    }
  }

  .viewed-products-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $tablet-max){
      padding: 7px var(--spacer-25);
    }

    @media (min-width: $desktop-min) {
      padding-bottom: 30px;
    }

    &__title {
      font-family: var(--font-family-secondary);
      font-size: var(--font-size-24);
      font-weight: var(--font-medium);
      line-height: normal;
    }
  }

  .viewed-products-wrap {
    background-color: var(--white);
    box-sizing: border-box;
    min-height: 505px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile-max) {
      padding: var(--spacer-20) var(--spacer-10);
    }

    @include for-tablet {
      padding: var(--spacer-30);
    }

    @include for-desktop {
      padding: var(--spacer-50);
    }

    .viewed-products-empty {
      text-align: center;
      color: var(--dark-gray);

      &__title {
        font-size: var(--font-size-20);
        font-weight: var(--font-medium);
        margin-top: var(--spacer-35);
        line-height: var(--font-size-20);
      }

      &__message {
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        color: var(--dark-gray);
        margin-top: var(--spacer-15);
        line-height: var(--font-size-17);
      }

      &__action {
        margin-top: var(--spacer-40);
        width: 100%;

        @media (max-width: $mobile-max) {
          margin-top: var(--spacer-30);
        }

        button {
          font-size: var(--font-size-18);
          line-height: var(--font-size-24);
          margin: 0 auto;

          @media (max-width: $mobile-max) {
            --button-width: 100%;
          }

          @media (min-width: $tablet-min) {
            --button-width: 247px;
          }
        }
      }
    }

    .viewed-list-wrap {
      box-sizing: border-box;
      flex: 1;

      ::v-deep {
        .products__grid {
          display: flex;
          flex-wrap: wrap;
        }

        .sf-product-card {
          z-index: 0;
        }

        @include for-desktop {
          .m-category-list__item {
            flex: 1 1 25%;
          }
        }

        @media (max-width: $mobile-max) {
          z-index: 1;
        }
      }
    }
  }

  .products__load-more {
    margin: var(--spacer-40) auto 0;

    @media (max-width: $mobile-max) {
      margin: 0 auto var(--spacer-20);
    }
  }
}
</style>
