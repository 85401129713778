const readFile = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = function () {
    resolve(reader.result);
  };

  reader.onerror = function (error) {
    reject(`Error: ${error}`);
  };
})

const initImage = (source): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
  const img = new Image()

  img.src = source;

  img.onload = function () {
    resolve(img);
  };

  img.onerror = function (error) {
    reject(`Image not ready: ${error}`);
  };
})

export const downscaleImage = async (source, maxWidth = 100, maxHeight = 100) => {
  try {
    const base64 = await readFile(source)
    const img = await initImage(base64)

    const canvas = document.createElement('canvas');
    canvas.width = maxWidth;
    canvas.height = maxHeight;

    const ctx = canvas.getContext('2d');

    const ratioWidth = maxWidth / img.width;
    const ratioHeight = maxHeight / img.height;
    const ratio = Math.max(ratioWidth, ratioHeight);

    const newWidth = img.width * ratio;
    const newHeight = img.height * ratio;
    const offsetX = (maxWidth - newWidth) / 2;
    const offsetY = (maxHeight - newHeight) / 2;

    ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

    return canvas.toDataURL('image/jpeg', 1)
  } catch (e) {
    throw new Error(e)
  }
}
