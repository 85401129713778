/* eslint-disable no-new */
<template>
  <div class="m-update-personal-data">
    <div class="form-account-wrapper">
      <div class="form__account-image">
        <SfImage
          :src="avatarUrl"
          :alt="$t('Account image')"
          class=""
          :width="100"
        />
        <label
          class="sf-icon color-white size-xs avatar-icon"
          for="avatar-input"
          data-transaction-name="PD - Change Avatar"
          @click="avatarInputLabelHandler"
        >
          <span class="avatar-photo" />
        </label>
        <div
          v-if="getUserAvatar"
          class="avatar-delete"
          data-transaction-name="PD - Delete Avatar"
          @click="deleteAvatar"
        >
          {{ $t('Delete') }}
        </div>
        <input
          v-if="!isMobile"
          id="avatar-input"
          class="avatar-input"
          type="file"
          @change="handleFileUpload($event)"
          accept="image/*"
        >
      </div>
      <form
        class="form"
        @submit.prevent="updatePersonalData"
      >
        <fieldset class="main-info fieldset">
          <div class="container">
            <div class="row">
              <div class="col">
                <SfInput
                  v-model.trim="firstname"
                  name="firstName"
                  :label="$t('First name')"
                  :valid="!$v.firstname.$error"
                  :error-message="$t('Please use only letters or symbols')"
                  class="form__element"
                  :class="$v.firstname.$error ? 'field-has-error' : ''"
                  data-transaction-name="PD - First Name - Field"
                />
              </div>
              <div class="col">
                <div class="form__element change-phone-input">
                  <SfInput
                    disabled
                    :value="formattedPhone"
                    name="phone"
                    id="phone"
                    data-transaction-name="PD - Phone - Field"
                    :label="$t('Phone for authorization')"
                  />
                  <!--
                  todo:: uncomment in version 2
                  <SfButton
                    class="sf-button--pure open-modal"
                    @click="openModalChangePhoneOnAccount"
                    type="button"
                  >
                    {{ $t('Change') }}
                  </SfButton>
                  -->
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset class="additional-phones fieldset">
          <legend class="legend">
            {{ $t('Additional numbers') }}
          </legend>
          <p v-if="!additionalPhones.length">
            {{ $t('You can quickly change the number when placing an order, or if your main number is not available, we can contact you.') }}
          </p>
          <div v-else class="exist-phones">
            <div
              v-for="(additionalPhone, i) in additionalPhones"
              :key="i"
              class="exist-phone"
            >
              <div class="exist-phone__number">
                {{ getFormattedPhoneLocal(additionalPhone) }}
              </div>
              <div
                data-transaction-name="PD - Delete Additional Phone"
                @click="deletePhone(additionalPhone)"
                class="exist-phone__action"
              >
                {{ $t('Delete') }}
              </div>
            </div>
          </div>
          <AAddNewLink
            :text="'Add another phone'"
            :data-transaction-name="'Add phone'"
            @click="openModalAddPhone"
          />
          <hr class="sf-divider">
        </fieldset>
        <fieldset class="personal-data fieldset">
          <legend class="legend">
            {{ $t('Personal data') }}
          </legend>
          <div class="container">
            <div class="row">
              <div class="col">
                <SfInput
                  v-model="email"
                  name="email"
                  :label="$t('E-mail')"
                  :valid="!$v.email.$error"
                  :error-message="$t('Please provide valid e-mail address.')"
                  data-transaction-name="PD - Email - Field"
                />
              </div>
              <div class="col">
                <MDob
                  @dobChange="setDob"
                  :dob="dob"
                />
              </div>
            </div>
          </div>
        </fieldset>
        <hr class="sf-divider">
        <fieldset class="gender-info fieldset">
          <legend class="legend">
            {{ $t('Gender') }}
          </legend>
          <MTags
            :tags="genderTags"
            @setActiveCategory="selectGender"
            :active-tag-index="getSelectedGenderIndex()"
          />
        </fieldset>
        <hr class="sf-divider">
        <fieldset class="additional-info fieldset">
          <div class="additional-info__loader" :class="{'additional-info__loader--show': isLoadingOtp}">
            <ALoadingSpinner
              :size="14"
              :weight="2"
              :is-absolute-position="true"
            />
          </div>
          <SfLink
            data-transaction-name="PD - Change Pin"
            @click.prevent="onChangePinClick"
            class="change-pin-link"
            :class="{'change-pin-link--disable': isLoadingOtp}"
          >
            <span v-if="validationStatus === 'pin'">{{ $t('Change PIN') }}</span>
            <span v-else>{{ $t('Add PIN') }}</span>
          </SfLink>
        </fieldset>
        <fieldset class="submit-button fieldset">
          <SfButton
            class="form__button sf-button--primary"
            type="submit"
          >
            {{ $t('Save changes') }}
          </SfButton>
        </fieldset>
      </form>
    </div>
    <div v-if="isAvatarPopupShown" class="avatar-popup" :style="avatarPopupStyle">
      <div class="avatar-popup__wrap">
        <div class="avatar-popup__actions">
          <div
            v-if="getUserAvatar"
            class="actions-item"
            data-transaction-name="PD - Delete Avatar"
            @click="deleteAvatar"
          >
            {{ $t('Delete avatar') }}
          </div>
          <div class="actions-item">
            <label for="avatar-input-make">
              {{ $t('Make photo') }}
            </label>
            <input
              v-if="isMobile"
              id="avatar-input-make"
              class="avatar-input"
              type="file"
              @change="handleFileUpload($event)"
              accept="image/*"
              capture
            >
          </div>
          <div class="actions-item">
            <label for="avatar-input-select">
              {{ $t('Select photo') }}
            </label>
            <input
              v-if="isMobile"
              id="avatar-input-select"
              class="avatar-input"
              type="file"
              @change="handleFileUpload($event)"
              accept="image/*"
            >
          </div>
        </div>
        <div class="avatar-popup__actions">
          <div
            class="actions-item item-cancel"
            data-transaction-name="PD - Cancel Avatar Change"
            @click="closeAvatarPopup"
          >
            {{ $t('Cancel') }}
          </div>
        </div>
      </div>
    </div>

    <!--<p class="notice">
      {{ $t('At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the') }}
      <a :href="localizedRoute('/privacy')">{{ $t('Privacy Policy') }}</a>.
    </p>-->
  </div>
</template>

<script>
import config from 'config';
import pick from 'lodash/pick';
import { SfButton, SfImage, SfLink } from '@storefront-ui/vue';
import { email } from 'vuelidate/lib/validators';
import { isNameValid } from 'theme/helpers/validation';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ModalList } from 'theme/store/ui/modals';
import { getCleanedPhone, getFormattedPhone } from 'theme/helpers/text';
import { downscaleImage } from 'theme/helpers/image';
import DeviceType from 'theme/mixins/DeviceType';
import { Logger } from '@vue-storefront/core/lib/logger';
import SfInput from 'theme/components/storefront-override/SfInput'
import AAddNewLink from 'theme/components/atoms/a-add-new-link.vue';
import MTags from 'theme/components/molecules/m-tags';
import MDob from 'theme/components/molecules/m-dob.vue';
import { eSputnikEvent } from 'theme/helpers/es';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import SmsBlock from 'theme/mixins/SmsBlock';
import { groups, sendToLogs } from 'theme/helpers/web-logging';

export default {
  name: 'MUpdatePersonalData',
  components: {
    ALoadingSpinner,
    AAddNewLink,
    SfInput,
    SfButton,
    SfImage,
    SfLink,
    MTags,
    MDob
  },
  mixins: [DeviceType, SmsBlock],
  inject: ['appProvided'],
  data () {
    return {
      firstname: '',
      email: '',
      phone: '',
      avatar: '',
      additionalPhones: [],
      isAvatarPopupShown: false,
      file: null,
      dob: null,
      genderTags: [
        {
          name: this.$t('Undefined'),
          value: 0
        },
        {
          name: this.$t('Male'),
          value: 1
        },
        {
          name: this.$t('Female'),
          value: 2
        }
      ],
      selectedGender: 0,
      isLoadingOtp: false
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current,
      canSendSms: state => state.ui.canSendSms
    }),
    ...mapGetters({
      getUserAvatar: 'userInfo/getUserAvatar',
      validationStatus: 'userInfo/validationStatus'
    }),
    avatarUrl () {
      return this.getUserAvatar ? this.getUserAvatar : '/assets/account/avatar.svg'
    },
    formattedPhone () {
      return getFormattedPhone(this.phone)
    },
    browserHeight () {
      return this.appProvided.browserHeight || window?.innerHeight
    },
    avatarPopupStyle () {
      return { 'height': `${this.browserHeight}px` }
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      userUpdate: 'userInfo/update',
      sendOtp: 'userInfo/sendOtp',
      uploadAvatar: 'userInfo/uploadAvatar',
      getAvatar: 'userInfo/getAvatar',
      refreshUserData: 'user/me',
      deleteAdditionalPhone: 'userInfo/deleteAdditionalPhone',
      spawnNotification: 'notification/spawnNotification',
      hideBottomNavigation: 'ui/hideBottomNavigation'
    }),
    handleFileUpload (e) {
      if (!e.target.files.length) {
        this.onFailure({ result: 'File is not selected' });
        return
      }

      const inputFile = e.target.files[0];

      if (!inputFile.type.match('image')) {
        this.onFailure({ result: 'Invalid file type. An image file is required' });
        return
      }

      let app = this;

      downscaleImage(inputFile, 150, 150)
        .then(resizedFile => {
          app.file = resizedFile
          app.uploadFile()
        })
        .catch(error => {
          console.log('Error: ', error);
          this.onFailure({ result: 'Can\'t place order' });
        })
    },
    deleteAvatar () {
      this.file = ''
      this.uploadFile('delete')
    },
    async uploadFile (type = 'save') {
      try {
        const response = await this.uploadAvatar({ base64String: this.file })
        this.file = ''
        if (response.code === 200) {
          this.hideAvatarPopup()
          void this.getAvatar()
          if (type === 'save') this.onSuccess(this.$t('Avatar is saved'))
          if (type === 'delete') this.onSuccess(this.$t('Avatar is deleted'))
        } else {
          if (type === 'save') this.onFailure({ result: 'Unexpected error when avatar uploading' });
          if (type === 'delete') this.onFailure({ result: 'Unexpected error when avatar deleting' });
        }
      } catch (error) {
        this.file = ''
        if (type === 'save') this.onFailure({ result: 'Unexpected error when avatar uploading' });
        if (type === 'delete') this.onFailure({ result: 'Unexpected error when avatar deleting' });
      }
    },
    async updatePersonalData () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.displayValidationError();
        return;
      }
      let updatedProfile = this.prepareCurrentProfile();
      updatedProfile = this.collectProfileDataForUpdate(updatedProfile)
      await this.updateUserProfile(updatedProfile);
      eSputnikEvent('CustomerData', this.currentUser);
    },
    async updateUserProfile (profile) {
      try {
        await this.userUpdate({ customer: profile });
      } catch (err) {
        Logger.error(err, 'userInfo/update')();
        this.onFailure({ result: 'Account data has not been updated' });
      }
    },
    prepareCurrentProfile () {
      return pick(
        JSON.parse(JSON.stringify(this.$store.state.user.current)),
        config.users.allowModification
      );
    },
    displayValidationError () {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'danger',
        message: this.$t('Please fix the validation errors'),
        action1: { label: this.$t('OK') }
      });
    },
    collectProfileDataForUpdate (updatedProfile) {
      return {
        ...updatedProfile,
        firstname: this.firstname || '',
        email: this.email || '',
        dob: this.prepareDobForRequest(this.dob) || '',
        gender: this.selectedGender || 0
      };
    },
    prepareDobForRequest (date) {
      if (!date) return null

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear());

      return `${year}-${month}-${day}`
    },
    openModalAddPhone () {
      if (!this.checkCanSendSMS()) return

      this.$store.dispatch('ui/hideBottomNavigation', true)
      this.openModal({
        name: ModalList.AddAdditionalPhone
      })
    },
    openModalChangePin () {
      this.$store.dispatch('ui/hideBottomNavigation', true)
      this.openModal({ name: ModalList.ChangePinCode, payload: null })
    },
    onChangePinClick () {
      if (!this.checkCanSendSMS()) return

      this.sendSms('changePin')
    },
    async sendSms (action = '') {
      try {
        if (!this.canSendSms && action === 'changePin') {
          this.openModalChangePin()
          return
        }

        this.isLoadingOtp = true

        const response = await this.sendOtp(this.phone.split(' ').join(''))
        if (response.result?.status === 'success') {
          if (action === 'changePin') this.openModalChangePin()
        } else {
          this.onFailure({ result: this.$t('Unexpected error when sending a code by SMS') });
          sendToLogs(
            groups.Auth,
            'otp:mUpdatePersonalData:sendSms:not:success',
            {
              code: response.code,
              result: response?.result || null
            },
            response?.transparentId
          )
        }
      } catch (error) {
        this.onFailure({ result: this.$t('Unexpected error when sending a code by SMS') });
      } finally {
        this.isLoadingOtp = false
      }
    },
    async deletePhone (phone) {
      try {
        const response = await this.deleteAdditionalPhone({ oldAdditionalPhone: getCleanedPhone(phone) })

        if (response.result?.status === 'success') {
          this.refreshUserData({ refresh: true, useCache: false })
        } else {
          this.onFailure({ result: this.$t('Additional phone is not deleted') });
        }
      } catch (error) {
        this.onFailure({ result: this.$t('Additional phone is not deleted') });
      }
    },
    getFormattedPhoneLocal (phone) {
      return getFormattedPhone(phone)
    },
    onSuccess (message) {
      this.spawnNotification({
        type: 'success',
        message: message,
        action1: { label: this.$t('OK') }
      });
    },
    onFailure (result) {
      this.spawnNotification({
        type: 'danger',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      });
    },
    avatarInputLabelHandler () {
      if (!this.isMobile) {
        this.hideAvatarPopup()
        return;
      }
      this.showAvatarPopup()
      this.hideBottomNavigation(true)
    },
    showAvatarPopup () {
      this.isAvatarPopupShown = true
    },
    hideAvatarPopup () {
      this.isAvatarPopupShown = false
    },
    closeAvatarPopup () {
      this.hideAvatarPopup()
      this.hideBottomNavigation(false)
    },
    selectGender (index) {
      this.selectedGender = this.genderTags[index].value
    },
    setDob (date) {
      this.dob = date
    },
    getSelectedGenderIndex () {
      return this.genderTags.findIndex(tag => tag.value === this.selectedGender);
    },
    prepareDateToInit (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return new Date(year, month - 1, day)
    }
  },
  beforeMount () {
    // current user may not be available yet in beforeMount hook so vuex watcher is needed
    const unsubscribeFromStoreWatch = this.$store.watch(
      state => state.user.current,
      currentUser => {
        if (currentUser) {
          const { firstname, email, phone, additional_phones, gender, dob } = currentUser;

          this.firstname = firstname;
          this.email = email;
          this.phone = phone;
          this.additionalPhones = additional_phones || [];
          this.selectedGender = gender;
          this.dob = this.prepareDateToInit(dob);
        }
      },
      { immediate: true });

    this.$once('hook:beforeDestroy', unsubscribeFromStoreWatch)
  },
  validations: {
    firstname: {
      isNameValid
    },
    email: {
      email
    }
  }
};

</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import "~theme/css/px2rem";
@import '~theme/css/fonts';

.form-account-wrapper {
  position: relative;
  padding: var(--spacer-15) var(--spacer-10) 0;

  @media (min-width: $tablet-min) {
    display: flex;
    padding: 0;
  }
}

.form {
  font-family: var(--font-family-inter);
  font-weight: var(--font-normal);

  &__account-image {
    position: relative;
    text-align: center;
    padding-bottom: var(--spacer-sm);
    margin-right: 0;

    @media (min-width: $tablet-min) {
      text-align: left;
      padding-bottom: 0;
      margin-right: var(--spacer-40);
    }

    ::v-deep {
      .sf-image {
        border-radius: 50%;

        img {
          display: block;
          object-fit: cover;
          max-width: 100px;
          width: 100px;
          height: 100px;
        }
      }
    }

    .sf-icon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(50%);
      background-color: var(--orange);
      border-radius: 50%;
      width: 2.25rem;
      height: 2.25rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sf-icon:hover {
      background-color: var(--orange-hover);
    }

    .avatar-delete {
      color: var(--orange);
      cursor: pointer;
      font-size: var(--font-size-14);
      font-family: var(--font-family-inter);
      font-weight: var(--font-normal);
      text-align: center;

      @media (max-width: $mobile-max) {
        display: none;
      }
    }

    .avatar-delete:hover {
      color: var(--orange-hover);
    }

    .avatar-photo {
      @include font-icon(var(--icon-photo));

      &:before {
        color: var(--white);
        font-size: var(--font-size-24);
      }
    }
  }

  @media (min-width: $tablet-min) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__button {
    font-size: var(--font-size-18);
    line-height: var(--font-size-24);

    @media (max-width: $mobile-max) {
      margin-bottom: var(--spacer-20);
      --button-width: 100%;
    }

    @include for-tablet {
      --button-width: 471px;
    }

    @include for-desktop {
      --button-width: 345px;
    }
  }

  .field-has-error {
    @media (max-width: $mobile-max) {
      margin-bottom: var(--spacer-30) !important;
    }
  }
}

.fieldset {
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;

  @media (min-width: $tablet-min) {
    display: flex;
  }

  .exist-phones {
    margin: var(--spacer-2xs) 0 var(--spacer-15);

    .exist-phone {
      display: flex;
      font-size: var(--font-sm);
      line-height: var(--font-size-17);

      &__number {
        margin-right: var(--spacer-20);
        min-width: px2rem(142);
      }

      &__action {
        cursor: pointer;
        color: var(--orange)
      }
    }

    .exist-phone:not(:last-child) {
      margin-bottom: var(--spacer-8);
    }
  }

  ::v-deep {
    input[name="phone"] {
      --input-color: var(--black);
      -webkit-text-fill-color: var(--black);

      &:disabled {
        --input-color: var(--black);
        -webkit-text-fill-color: var(--black);
        color: var(--black);
        opacity: .4;
      }
    }

    input,
    .sf-select-option {
      background-color: var(--light-gray);
      height: 3.5rem;

      & ~ .sf-input__label,
      & ~ .sf-select__label {
        font-size: var(--font-size-12);
        color: var(--dark-gray);
      }

      &:focus ~ * {
        --input-label-top: 20%;
      }
    }
  }
  .sf-input--has-text {
    ::v-deep  .sf-input__label {
      --input-label-top: 20%;
    }
  }

  &.main-info {
    position: relative;
    margin-bottom: calc(var(--spacer-50) / 2);

    .change-phone-input {
      position: relative;

      .open-modal {
        position: absolute;
        right: 1rem;
        top: var(--spacer-20);
        font-size: var(--font-size-14);
        font-weight: var(--font-normal);
        color: var(--orange);
        cursor: pointer;
      }
    }
  }

  &.personal-data,
  &.additional-phones {
    flex-direction: column;

    p {
      margin: 0 0 var(--spacer-10);
      font-size: var(--font-size-13);
      font-weight: var(--font-normal);
      color: var(--dark-gray);
    }

    .sf-divider {
      @media (max-width: $mobile-max) {
        margin-top: var(--spacer-25);
        margin-bottom: var(--spacer-25);
      }

      @media (min-width: $tablet-min) {
        margin-top: var(--spacer-30);
        margin-bottom: var(--spacer-30);
      }
    }
  }

  &.personal-data {
    .legend {
      margin-bottom: 1rem;
    }

    .sf-divider {
      margin-top: var(--spacer-10);
    }
  }

  &.personal-data,
  &.main-info {
    ::v-deep {
      .sf-input {
        input {
          border: none;
          --input-bar-display: none;

          &:focus {
            border: 1px solid var(--orange);
            box-sizing: border-box;
          }

          &:focus-visible {
            outline: none;
            border: 1px solid var(--orange);
            box-sizing: border-box;
          }
        }
      }

      .sf-input__bar {
        display: none;
      }
    }
  }

  &.additional-info {
    margin-top: var(--spacer-10);
    margin-bottom: var(--spacer-40);

    .change-pin-link {
      transition: opacity 200ms;
      opacity: 1;

      &--disable {
        opacity: .8;
        pointer-events: none;
      }
    }

    .additional-info__loader {
      display: flex;
      align-items: center;
      position: relative;
      width: 0;
      overflow: hidden;
      transition: all 200ms;
      opacity: 0;

      &--show {
        width: 24px;
        opacity: 1;
      }
    }

    a {
      span {
        color: var(--orange);
        font-size: var(--font-size-14);
      }
    }
  }
}

.legend {
  font-size: var(--font-size-16);
  font-weight: var(--font-medium);
  margin: 0 0 10px;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
}

.col {
  flex: 1 1 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  margin: 0 0 10px;

  @media (min-width: $tablet-min) {
    flex: 0 1 50%;
  }
}

.notice {
  max-width: 31rem;
  margin: var(--spacer-base) 0 0 0;
  font-size: var(--font-2xs);
}

.sf-divider {
  width: calc(100% + calc(2 * var(--spacer-10)));
  height: 2px;
  background-color: var(--light-gray);
  border: none;
  margin: var(--spacer-30) calc(-1 * var(--spacer-10));

  @media (min-width: $tablet-min) {
    width: 100%;
    margin: var(--spacer-20) 0;
    height: 1px;
    background-color: var(--color-zircon);
  }
}

.m-update-personal-data {
  background-color: var(--white);

  @media (min-width: $tablet-min) {
    padding: calc(5 * var(--spacer-10));
  }

  @media (max-width: $mobile-max) {
    position: relative;

    .avatar-popup {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0,0,0,0.4);
      z-index: calc(var(--header-sticky-index, 2) + 1);

      &__wrap {
        position: absolute;
        bottom: var(--spacer-20);
        left: var(--spacer-20);
        right: var(--spacer-20);
      }

      &__actions {
        background-color: var(--white);
        border-radius: var(--spacer-5);

        div {
          display: block;
          cursor: pointer;
          font-family: var(--font-family-inter);
          font-weight: var(--font-normal);
          text-align: center;
          padding: var(--spacer-15);
          color: var(--black)
        }

        div:hover {
          color: var(--orange-hover);
          background-color: var(--gray-30);
          border-radius: var(--spacer-5);
        }

        div:not(:last-child) {
          border-bottom: 1px solid var(--light-gray);
        }
      }

      .item-cancel {
        margin-top: var(--spacer-5);
        color: var(--orange)
      }
    }
  }

  .avatar-input {
    display: none;
  }
}

.gender-info {
  margin: 0 0 30px;
}

::v-deep  {
  .sf-input__wrapper {
    margin-bottom: 0;
  }

  .sf-input__error-message:empty {
    display: none;
  }

  .sf-select {
    --select-dropdown-z-index: 4;
  }

  .sf-select__selected {
    --select-selected-padding: 1.5rem var(--spacer-sm) .69rem var(--spacer-sm);
  }
}
</style>
