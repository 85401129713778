import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import i18n from '@vue-storefront/i18n'
import { getFormattedPhone } from 'theme/helpers/text'
import { deliveryMethods } from '$modules/shipping/config';

const deliveryMethodsLabels = {
  'pickup': i18n.t('Pickup'),
  'varus_drive': i18n.t('varus_drive')
};

export function getDeliveryMethod (method = '') {
  if (deliveryMethods.includes(method?.toUpperCase())) return i18n.t('delivery')

  return deliveryMethodsLabels[method] || i18n.t(method?.toUpperCase() || '');
}

const paymentMethods = {
  'CASH_CARD': i18n.t('Payment on receipt'),
  'DELIVERY_CASH': i18n.t('Cash on delivery'),
  'DELIVERY_CARD': i18n.t('Card on delivery'),
  'CRYPTO_ONLINE': i18n.t('Cryptocurrency online after assembling the order'),
  'CARD_ONLINE': i18n.t('Card online'),
  'HOLD': i18n.t('By card when placing an order')
};

export function getPaymentMethod (method = '') {
  return paymentMethods[method] || '';
}

export function getReplacement (value = '') {
  let result
  switch (value) {
    case 'replace': result = i18n.t('Replace items at the picker choice'); break
    case 'do_no_replace': result = i18n.t('Do not replace products if they are not available at the time of ordering'); break
    default: result = ''
  }

  return result
}

export function getPackaging (value = '') {
  let result
  switch (value) {
    case 'min_packages': result = i18n.t('Minimum packages'); break
    case 'other_packages': result = i18n.t('Other packages'); break
    case 'do_no_pack': result = i18n.t('Do not pack'); break
    default: result = ''
  }

  return result
}

const statusInfoMap = {
  'new': {
    group: 'non-fiscalized',
    class: 'order-status-new',
    title: i18n.t('New')
  },
  'collecting': {
    group: 'non-fiscalized',
    class: 'order-status-process',
    title: i18n.t('Process')
  },
  'collected': {
    group: 'non-fiscalized',
    class: 'order-status-collected',
    title: i18n.t('Collected')
  },
  'completed': {
    group: 'fiscalized',
    class: 'order-status-completed',
    title: i18n.t('Completed')
  },
  'canceled': {
    group: 'non-fiscalized',
    class: 'order-status-canceled',
    title: i18n.t('Canceled')
  },
  'closed': {
    group: 'fiscalized',
    class: 'order-status-closed',
    title: i18n.t('Closed')
  },
  'delivery': {
    group: 'fiscalized',
    class: 'order-status-delivery',
    title: i18n.t('Is delivered')
  }
};

export function getStatusInfo (value = '') {
  return statusInfoMap[value] || { group: '' };
}

export function getMoneyFormat (val = 0, number = 2, hideLeadingZeros = false) {
  try {
    let value = val
    if (typeof val === 'string') value = parseFloat(val || 0)
    if (hideLeadingZeros && value % 1 === 0) number = 0

    return value.toFixed(number)
  } catch (e) {
    return null
  }
}

export function dateAdd (date, type, amount) {
  let y = date.getFullYear()
  let m = date.getMonth()
  let d = date.getDate()

  if (type === 'y') y += amount
  if (type === 'm') m += amount
  if (type === 'd') d += amount

  return new Date(y, m, d);
}

export function getFullDayDates (date, type = 'start') {
  if (!date) return ''

  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  const time = type === 'start' ? '00:00:00' : '23:59:59'

  month = (month < 10) ? '0' + month : month;
  day = (day < 10) ? '0' + day : day;

  return year + '-' + month + '-' + day + ' ' + time
}

export function getDateTimeObject (date: string, needTimezoneOffset = false) {
  if (typeof date !== 'string') return ''
  let dateTimeObject = new Date(date.replace(' ', 'T'))
  if (isNaN(dateTimeObject.valueOf())) return date

  if (needTimezoneOffset) {
    const offset = new Date().getTimezoneOffset() * 60 * 1000 * (-1)
    dateTimeObject = new Date(dateTimeObject.getTime() + offset)
  }

  return dateTimeObject
}

export function getFormattedDateTime (date: string, showTime = true, needTimezoneOffset = false) {
  if (typeof date !== 'string') return ''

  const dateTimeObject: any = getDateTimeObject(date, needTimezoneOffset)
  const currentLocale = currentStoreView().i18n.defaultLocale
  const year = dateTimeObject.getFullYear()
  const month = dateTimeObject.toLocaleDateString(currentLocale, { month: 'short' })
  const day = dateTimeObject.getDate()

  if (!showTime) return day + ' ' + month + '. ' + year

  const hours = dateTimeObject.getHours()
  let minutes: string | number
  minutes = dateTimeObject.getMinutes()
  minutes = (+minutes < 10) ? '0' + minutes : minutes;

  return day + ' ' + month + ' ' + year + ', ' + hours + ':' + minutes
}

export function getIsZeroValue (value = '') {
  return typeof value === 'string' ? parseFloat(value) < 0.01 : false
}

export function prepareOrder (order) {
  return {
    item_id: order.id,
    cheque_id: order.cheque_id,
    amount: getMoneyFormat(order.total_paid, 2, false),
    total: getMoneyFormat(order.total, 2, false),
    discount: {
      base: getMoneyFormat(order.discount?.base_discount, 2, false) || '',
      coupon: getMoneyFormat(order.discount?.coupon_discount, 2, false) || ''
    },
    status: order.status,
    statusInfo: getStatusInfo(order.status),
    type: order.type,
    number: order.increment_id,
    date: getFormattedDateTime(order.date, true, true),
    dateTimeObject: getDateTimeObject(order.date, true),
    address: order.address || '',
    bonus_credit: getMoneyFormat(order.bonus_credit, 2, false),
    bonus_debit: getMoneyFormat(order.bonus_debit, 2, false),
    money_credit: getMoneyFormat(order.money_credit, 2, false),
    money_debit: getMoneyFormat(order.money_debit, 2, false),
    weight: order.weight,
    tips_total: order.tips_total || null,
    shipping_total: getMoneyFormat(order.shipping_total, 2, false),
    packaging_total: getMoneyFormat(order.packaging_total, 2, false),
    collecting_total: (
      order.collecting_total ? getMoneyFormat(order.collecting_total, 2, false) : null
    ),
    payment_card_mask: order.payment?.card_mask || '',
    payment_card_type: order.payment?.card_type || '',
    payment_receipt_link: order.payment?.receipt_link || null,
    payment_method: order.payment?.method || '',
    delivery_method: order.delivery?.method || '',
    delivery_address: order.delivery?.address || '',
    replacement: order.delivery?.replacement || '',
    packaging: order.delivery?.packaging || '',
    timeslot: order.delivery?.timeslot || [],
    shipping_phone: getFormattedPhone(order.shipping_phone) || '',
    shipping_name: order.shipping_name || '',
    recipient: order.delivery?.recipient || '',
    items: order.items || []
  }
}
